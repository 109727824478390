/**** IMPORT ****/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/**** COLORS ****/
/**** FONT ****/
/**** WIDTHS ****/
/**** HEIGHT ****/
/**** RESPONSIVE ****/
/**** OTHER ****/
:export {
  breakpointMedium: 720px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a {
  transition: 0.2s ease-out;
}

a:hover {
  transition: 0.2s ease-out;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: #666;
  background: #f9f9f9;
  position: relative;
}

aside {
  display: block;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 5em;
  background: #fff;
}

aside .logo {
  padding-top: 2em;
}

aside nav ul {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 1.25em;
}

aside nav .menu {
  display: none;
}

aside nav ul li {
  display: block;
  width: 100%;
  text-align: center;
  padding: 1em 0;
  transition: 0.2s ease-out;
  color: #8a8a8a;
  cursor: pointer;
}

aside nav ul li:hover,
aside nav ul li.active {
  border-left: 5px solid #1a8fff;
  transition: 0.2s ease-out;
  color: #1a8fff;
}

aside nav .link-text {
  display: none;
}

header {
  position: relative;
  top: 0px;
  width: 100%;
  background: transparent;
}

main {
  display: block;
  width: calc(100% - 5em);
  margin-left: 5em;
  position: relative;
}

.container {
  margin: 0 auto;
  display: block;
  position: relative;
  max-width: 840px;
  height: 100vh;
  flex: 1;
}
.container.small {
  max-width: 550px;
}
.container.top {
  margin-top: 6em;
  margin-bottom: 6em;
}

.box {
  padding: 3em 3em 5em;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  position: relative;
}
.box.bg {
  background: url("../img/ReggoraGrayLight.png") no-repeat 125% 130% #fff;
  background-size: 60%;
}
@media (min-width: 720px) {
  .box {
    padding: 4em 5em;
  }
}
@media (max-width: 720px) {
  .box.calendar {
    padding: 0;
    border: 0;
    background: none;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

.button {
  display: inline-block;
  margin-top: 2em;
  text-decoration: none;
  padding: 0.5em 2em;
  border-radius: 0.25em;
  color: #fff;
  background: #35cc62;
  border: 2px solid #35cc62;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.button:hover {
  background: #fff;
  color: #35cc62;
  transform: scale(1.1);
  transition: 0.2s ease-out;
}
.button + .button {
  margin-left: 1em;
}
.button.no-transform {
  margin-top: 0;
}
.button.no-transform:hover {
  transform: none;
}
.button.secondary {
  color: #35cc62;
  background: #fff;
  border: 2px solid #35cc62;
}
.button.secondary:hover {
  color: #fff;
  background: #35cc62;
}
.button.error {
  background: #fff;
  color: #ff2e2e;
  border: 2px solid #ff2e2e;
}
.button.error:hover {
  background: #ff2e2e;
  color: #fff;
}

.button-disabled {
  display: inline-block;
  margin-top: 2em;
  text-decoration: none;
  padding: 0.5em 2em;
  border-radius: 0.25em;
  color: #fff;
  background: #666666;
  border: 2px solid #666666;
  cursor: default;
}
.button-disabled.no-transform {
  margin-top: 0;
}
.button-disabled.no-transform:hover {
  transform: none;
}

.button + .button-disabled {
  margin-left: 1em;
}

form {
  display: block;
  width: 100%;
}

input,
select,
textarea {
  width: 100%;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 0.875em;
  background: transparent;
  padding: 1em 0.5em;
  border: none;
  color: #8a8a8a;
  border-bottom: 1px solid #eee;
  transition: 0.2s ease-out;
}
input + input,
select + input,
textarea + input {
  margin-top: 1.3em;
}
input:focus,
select:focus,
textarea:focus {
  color: #1a8fff;
  border-bottom: 1px solid #1a8fff;
  outline: 0;
  transition: 0.2s ease-out;
}

.input-icon {
  position: relative;
}
.input-icon + .input-icon {
  margin-top: 1em;
}
.input-icon span {
  position: absolute;
  top: 0.75em;
  left: 0.5em;
  font-size: 112.5%;
  color: rgba(102, 102, 102, 0.5);
  transition: 0.2s ease-out;
}
.input-icon input {
  padding-bottom: 1em;
  padding-left: 3em;
}
.input-icon input:focus ~ span {
  color: #1a8fff;
  transition: 0.2s ease-out;
}

input.radio {
  width: auto;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 0.875em;
  background: transparent;
  padding: 2em 0.5em;
  border: none;
  color: #8a8a8a;
  border-bottom: 1px solid #eee;
  transition: 0.2s ease-out;
}

.row-flex {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.row-flex.row-flex-reverse {
  flex-direction: row-reverse;
}
.row-flex.row-flex-no_wrap {
  flex-wrap: nowrap;
}
.row-flex.row-flex-top {
  align-items: flex-start;
}
.row-flex.row-flex-middle {
  align-items: center;
}
.row-flex.row-flex-bottom {
  align-items: flex-end;
}
.row-flex.row-flex-stretch {
  align-items: stretch;
}
.row-flex.row-flex-baseline {
  align-items: baseline;
}
.row-flex .col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.row-flex .col.col-10 {
  flex-basis: 10%;
  width: 10%;
}
.row-flex .col.col-20 {
  flex-basis: 20%;
  width: 20%;
}
.row-flex .col.col-25 {
  flex-basis: 25%;
  width: 25%;
}
.row-flex .col.col-30 {
  flex-basis: 30%;
  width: 30%;
}
.row-flex .col.col-33 {
  flex-basis: 33.32%;
  width: 33.32%;
}
.row-flex .col.col-40 {
  flex-basis: 40%;
  width: 40%;
}
.row-flex .col.col-50 {
  flex-basis: 50%;
  width: 50%;
}
.row-flex .col.col-60 {
  flex-basis: 60%;
  width: 60%;
}
.row-flex .col.col-67 {
  flex-basis: 66.67%;
  width: 66.67%;
}
.row-flex .col.col-70 {
  flex-basis: 70%;
  width: 70%;
}
.row-flex .col.col-75 {
  flex-basis: 75%;
  width: 75%;
}
.row-flex .col.col-80 {
  flex-basis: 80%;
  width: 80%;
}
.row-flex .col.col-90 {
  flex-basis: 90%;
  width: 90%;
}
.row-flex .col.col-100 {
  flex-basis: 100%;
  width: 100%;
}
.row-flex .col.col-offset-10 {
  margin-left: 10%;
}
.row-flex .col.col-offset-20 {
  margin-left: 20%;
}
.row-flex .col.col-offset-25 {
  margin-left: 25%;
}
.row-flex .col.col-offset-30 {
  margin-left: 30%;
}
.row-flex .col.col-offset-33 {
  margin-left: 33.32%;
}
.row-flex .col.col-offset-40 {
  margin-left: 40%;
}
.row-flex .col.col-offset-50 {
  margin-left: 50%;
}
.row-flex .col.col-offset-60 {
  margin-left: 60%;
}
.row-flex .col.col-offset-67 {
  margin-left: 66.67%;
}
.row-flex .col.col-offset-70 {
  margin-left: 70%;
}
.row-flex .col.col-offset-80 {
  margin-left: 80%;
}
.row-flex .col.col-offset-90 {
  margin-left: 90%;
}
.row-flex .col.col-start {
  justify-content: flex-start;
  text-align: left;
}
.row-flex .col.col-center {
  justify-content: center;
  text-align: center;
}
.row-flex .col.col-end {
  justify-content: flex-end;
  text-align: right;
}

@media (max-width: 720px) {
  .row-flex {
    display: block;
    flex-direction: column;
    text-align: center;
  }
  .row-flex .col {
    display: block;
    width: 100%;
  }
  .row-flex .col.col-10, .row-flex .col.col-20, .row-flex .col.col-25, .row-flex .col.col-30, .row-flex .col.col-33, .row-flex .col.col-40, .row-flex .col.col-50, .row-flex .col.col-60, .row-flex .col.col-67, .row-flex .col.col-70, .row-flex .col.col-80, .row-flex .col.col-90 {
    display: block;
    width: 100%;
    margin-bottom: 2em;
    flex-basis: 100%;
    max-width: 100%;
  }

  .row-flex-row {
    display: flex;
    flex-direction: row !important;
  }
  .row-flex-row.col {
    margin: 0;
  }
}
ul.error-list {
  width: 100%;
  padding: 0;
  margin: 0.5em 0 0.75em;
  font-size: 0.875em;
}

ul.error-list li {
  color: #ff2e2e;
  list-style: none;
  text-align: left;
  border-left: 2px solid #ff2e2e;
  padding: 0.25em 0;
  padding-left: 1em;
}

b,
strong {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

p {
  display: block;
  width: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: 1;
}

h1 {
  font-size: 225%;
}
h1.title {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
h1 + p.info, h1 + p.intro, h1 + p.notif {
  margin-top: 1.5em;
  margin-bottom: 1em;
}
h1 + p.intro {
  margin-bottom: 1em;
}
h1 + p.notif {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
h1 .icon {
  color: #349bff;
  padding-right: 0.3em;
  margin-top: -5px;
}
@media (min-width: 720px) {
  h1 + p.info {
    margin-bottom: 3em;
  }
}

h2 {
  font-size: 150%;
  line-height: 1.35;
}

h3 {
  font-size: 125%;
  line-height: 1.25;
}

h4,
h5,
h6 {
  font-size: 110%;
  color: #8a8a8a;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

code {
  display: block;
  font-family: monospace;
  background: #0c2c3f;
  color: #d9d9d9;
  max-height: 15em;
  overflow-y: auto;
}

blockquote {
  border-left: 4px solid #1a8fff;
}

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.body-text {
  margin: 24px 0;
}

.caption {
  font-size: 12px;
  font-style: italic;
}

.center-all {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
}
@media (max-width: 1200px) {
  .center-all {
    width: 100%;
    position: relative;
    left: 0;
    top: 12em;
    transform: none;
    margin-bottom: 2em;
    background: #fff;
  }
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 840px;
}

.margin-top {
  margin-top: 1em;
}

.margin-bottom {
  margin-bottom: 1em;
}

.margin-left {
  margin-left: 1em;
}

.margin-right {
  margin-right: 1em;
}

.d-flex {
  display: flex;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.powered-by {
  position: absolute;
  bottom: 1em;
  right: 2em;
}

.reggora-blue {
  color: #1890ff;
}

main {
  display: flex;
  justify-content: center;
  width: calc(100% - 4em);
  margin: 0;
  padding: 0em;
  margin: 2em 2em 5em;
}
@media (min-width: 720px) {
  main {
    margin: 4em 0;
    width: 100%;
  }
}

.disabled {
  pointer-events: none;
  background: #f6f6f6;
}

.animate {
  animation-name: div-visibility;
  animation-duration: 1s;
}

.clickable {
  font-size: 125%;
  transition: 0.2s ease-out;
}

.clickable:hover {
  transform: scale(1.5);
  cursor: pointer;
  transition: 0.2s ease-out;
}

.left {
  float: left;
}

.right {
  float: right;
}

.margin-left {
  margin-left: 1em;
}

.margin-right {
  margin-right: 1em;
}

.margin-bottom {
  margin-bottom: 1em;
}

.green {
  color: #35cc62;
}

.scroll-me {
  display: none;
  margin: 0 auto;
  height: 2em;
  width: 4em;
  border: 2px solid #ccc;
  border-radius: 1em;
  position: relative;
}

.scroll-dot {
  background: #ccc;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0%;
  animation: toto 2s infinite;
}

.success {
  position: relative;
  font-size: 2em;
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  border: 2px solid #35cc62;
  margin: 0 auto;
  margin-bottom: 1.5em;
}

.radio-form {
  display: grid;
  justify-content: center;
  text-align: start;
}

.radio-line {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.radio {
  margin-right: 8px;
}

/* FORM */
.form {
  display: block;
  padding: 0;
  margin: 0;
  margin-top: 3em;
  border: 1px solid #eee;
  border-radius: 0.5em;
  padding: 3em;
  overflow: auto;
  box-shadow: 0px 0px 20px #eee;
}

.form img {
  max-width: 15em;
  height: auto;
  float: left;
  margin-bottom: 2.25em;
}

.form .button {
  float: right;
  margin-top: 2.25em;
  padding: 0.25em 1.75em;
  font-weight: 400;
  text-transform: uppercase;
}

.third {
  width: 30%;
  float: left;
  margin-right: 5%;
  margin-top: 2em;
}

.no-margin {
  margin-right: 0;
}

.icon-cell {
  font-size: 1.25em;
  color: #35cc62;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

p.background {
  position: absolute;
  font-size: 2000%;
  color: #eee;
  font-weight: 700;
  margin: 0;
  bottom: 0;
  right: 0;
  line-height: 1;
}

.border {
  border-top: 1px solid #eee;
  margin: 0;
  padding: 0;
  margin-top: -1px;
  padding-bottom: 2em;
}

.tab {
  display: inline-block;
  font-size: 90%;
  color: #8a8a8a;
  padding: 0.75em 1.5em;
  position: relative;
  z-index: 2;
  border: 1px solid #fff;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.tab.active {
  border: 1px solid #eee;
  border-bottom: 1px solid #fff;
  transition: 0.2s ease-out;
}
.tab:hover {
  color: #349bff;
  border: 1px solid #349bff;
  border-bottom: 1px solid #fff;
  transition: 0.2s ease-out;
}

.nav-bar {
  background: white;
  display: flex;
  height: 75px;
  padding: 2em;
  justify-content: center;
  align-items: center;
}

.logo {
  position: relative;
  height: 28px;
}
@media (min-width: 720px) {
  .logo {
    height: 36px;
  }
}

.header-steps {
  flex: 1;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 720px) {
  .header-steps {
    padding-right: 5%;
    padding-left: 5%;
  }
}
.header-steps .ant-steps-item:last-child {
  flex: 1;
}

.header-steps-mobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.step-title-container {
  margin-right: 12px;
  text-align: right;
}

.step-title {
  font-size: 16px;
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 720px) {
  body {
    font-size: 0.75em;
  }
}
@media (max-width: 600px) {
  .scroll-me {
    display: block;
    margin-top: 1.5em;
  }
}
.card-element {
  justify-content: center;
  align-items: center;
  height: 50%;
}

.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.form-group {
  margin-bottom: 12px;
}
@media (min-width: 720px) {
  .form-group {
    margin: 0;
  }
}

.flex-row {
  display: flex;
}

.payment-row {
  display: flex;
  flex-direction: column;
}
@media (min-width: 720px) {
  .payment-row {
    flex-direction: row;
    margin: 12px;
  }
}
.payment-row .payment-row-mobile:last-child {
  margin: 0;
}

.payment-row-mobile {
  display: flex;
  flex: 1;
}
.payment-row-mobile .form-group {
  margin-right: 12px;
}
.payment-row-mobile .form-group:last-child {
  margin-right: 0;
}
@media (min-width: 720px) {
  .payment-row-mobile .form-group:last-child {
    margin-right: 12px;
  }
}

.flex-1 {
  flex: 1;
}

.m-2 {
  margin: 2%;
}

.fullwidth {
  width: 100%;
}

.cc-img {
  margin: 1em 0;
  width: 30%;
  align-self: center;
}
@media (min-width: 720px) {
  .cc-img {
    margin: 2%;
  }
}

.card-input {
  border-bottom: 1px solid gray;
}

.vgs-input iframe {
  height: 32px;
  width: 100%;
  border-bottom: 1px solid gray;
  padding-left: 4px;
}

.flex-2 {
  flex: 2;
}

.requirement-text-container {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.ant-progress-circle .ant-progress-text {
  font-weight: 600;
  font-size: 12px;
}

.calendarApp {
  display: block;
  max-width: 45.5em;
  position: relative;
  margin: 0 auto;
  overflow: auto;
  border: 1px solid #eee;
}

.calendarHeader {
  padding: 1.5em 0;
  font-weight: 700;
  font-size: 120%;
  border-bottom: 1px solid #eee;
  background: #fff;
}

.calendarDates {
  border-left: 4em solid #349bff;
  position: relative;
  z-index: 3;
  overflow-y: scroll;
}

.calendarDates span {
  display: inline-block;
  border-right: 1px solid #eee;
  width: calc(100% / 7);
  background: #fff;
}

.timezone {
  border-left: 4em solid #349bff;
  padding: 0.25em 0;
  position: relative;
  z-index: 1;
}

.time {
  max-height: 26em;
  overflow: auto;
  display: block;
  width: 100%;
}

.dateTime {
  display: block;
  width: 100%;
}

.dateTime .hours {
  width: 4em;
  height: 3em;
  background: #349bff;
  color: #cde6ff;
  float: left;
  position: relative;
  z-index: 2;
}

.hours span {
  position: absolute;
  display: block;
  width: 100%;
}

.dateTime .timeCells {
  float: left;
  width: calc(100% - 4em);
  height: 3em;
  position: relative;
  background: #fff;
}

.dateTime .cell {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: calc(100% / 7);
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: none;
}

.cellBox {
  background: #349bff;
  overflow: hidden;
  width: 100%;
  position: absolute;
  z-index: 10;
  width: calc(100% / 7);
  color: #4d4d4d;
  transition: 0.2s ease-out;
  padding-top: 4px;
}
.cellBox.calm-red {
  background-color: #ffcdc8;
}
.cellBox.calm-red:hover {
  box-shadow: 0px 7px 7px #ffcdc8;
}
.cellBox.calm-orange {
  background-color: #ffd9b8;
}
.cellBox.calm-orange:hover {
  box-shadow: 0px 7px 7px #ffd9b8;
}
.cellBox.calm-yellow {
  background-color: #fff2c7;
}
.cellBox.calm-yellow:hover {
  box-shadow: 0px 7px 7px #fff2c7;
}
.cellBox.calm-green {
  background-color: #d2f2e0;
}
.cellBox.calm-green:hover {
  box-shadow: 0px 7px 7px #d2f2e0;
}
.cellBox.calm-blue {
  background-color: #d2e1ff;
}
.cellBox.calm-blue:hover {
  box-shadow: 0px 7px 7px #d2e1ff;
}
.cellBox.calm-purple {
  background-color: #d4c9f6;
}
.cellBox.calm-purple:hover {
  box-shadow: 0px 7px 7px #d4c9f6;
}

.cellBox:hover {
  box-shadow: 0px 7px 7px #1a8fff;
  transform: translateY(-7px);
  cursor: pointer;
  transition: 0.2s ease-out;
}

.cellBox p {
  font-size: 0.8em;
  font-weight: 600;
}

.date {
  padding: 1em 0.5em;
  position: relative;
  border-bottom: 1px solid #eee;
}

.dateText {
  line-height: 1;
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: 400;
}
@media (min-width: 720px) {
  .dateText {
    font-size: 1em;
  }
}

.dateText b {
  color: #b3b3b3;
  font-size: 140%;
  margin: 0.5em 0 0.25em;
  display: block;
}

.date:hover .dateIcons {
  display: block;
}

.cell {
  height: 3em;
  border-bottom: 1px solid #eee;
  transition: 0.2s ease-out;
}
.cell .icon {
  font-size: 1.4em;
  margin-top: 0.5em;
  color: #86e1a1;
  opacity: 0;
}

.cell:hover {
  background: #fbfbfb;
  transition: 0.2s ease-out;
  cursor: pointer;
}
.cell:hover .icon {
  opacity: 1;
}

.time-input {
  display: inline-block;
}

.time-input input {
  width: 3em;
  text-align: center;
  font-size: 1.15em;
  padding: 0.5em 0;
  display: inline-block;
  vertical-align: middle;
}

.time-input span {
  font-weight: 700;
  padding: 0 0.5em;
}

.am-pm {
  margin-left: 0.75em;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: 400;
  font-size: 0.925em;
  color: var(--sec-text-color);
}

.am-pm p {
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.am-pm p + p {
  padding-top: 0.35em;
}

.am-pm p.active,
.am-pm p:hover {
  color: #4da8ff;
  font-weight: 700;
  transition: 0.2s ease-out;
  transform: scale(1.15);
}

.text-right-input {
  text-align: right;
  margin-right: 1em;
  display: inline-block;
  width: 3em;
}

.calendar-key h3 {
  margin: 12px;
  font-weight: 600;
}
.calendar-key .key-dot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: auto 10px auto 0;
}
.calendar-key .key-dot.calm-red {
  background-color: #ffcdc8;
}
.calendar-key .key-dot.calm-orange {
  background-color: #ffd9b8;
}
.calendar-key .key-dot.calm-yellow {
  background-color: #fff2c7;
}
.calendar-key .key-dot.calm-green {
  background-color: #d2f2e0;
}
.calendar-key .key-dot.calm-blue {
  background-color: #d2e1ff;
}
.calendar-key .key-dot.calm-purple {
  background-color: #d4c9f6;
}

.download {
  border: 1px solid #eee;
  text-align: left;
  margin: 1em 0;
  transition: 0.2s ease-out;
  display: block;
  position: relative;
}

.download .icon,
.download .text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.download p {
  line-height: 1;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #1a8fff;
}

.download span {
  font-size: 90%;
  color: #8a8a8a;
}

.download .icon {
  color: #1a8fff;
  font-size: 200%;
  line-height: 1;
  padding: 0;
  margin: 0;
  padding: 0.5em 1em;
}

.download:hover {
  box-shadow: 0px 0px 10px #eee;
  transform: scale(1.1);
  transition: 0.2s ease-out;
  cursor: pointer;
}

input[type=file] {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 4.75em;
  opacity: 0;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9000;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.direction-left {
  text-align: left;
  margin: 2rem; 

}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: var(--main-color);
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes toto {
  from {
    transform: translate(50%, -50%);
  }
  to {
    transform: translate(225%, -50%);
  }
}
@keyframes div-visibility {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
  overflow: hidden;
}
.modal .modal-container {
  position: absolute;
  width: calc(100% - 4em);
  height: auto;
  overflow: visible;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2em;
}
@media (min-width: 720px) {
  .modal .modal-container {
    width: 36em;
  }
}
.modal .modal-container .close {
  font-weight: 300;
  line-height: 0;
  position: absolute;
  top: 0.75em;
  right: 0.3em;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.modal .modal-container .close:hover {
  color: #35cc62;
  transform: scale(1.4);
  transition: 0.2s ease-out;
}
.modal .modal-container .content {
  margin-top: 1.5em;
  max-height: 70vh;
  overflow-y: visible;
}
.modal .modal-container .content h1 {
  font-weight: 400;
}
.modal .modal-container .content p.form-error {
  color: #ff2e2e;
  margin-top: 1.5em;
}

.notifications {
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 2;
}

.notification {
  box-shadow: 0 0 20px #eee;
  padding: 1em 2.5em 1em 1em;
  border-bottom: 3px solid #ff2e2e;
  width: 25em;
  position: relative;
  background: #fff;
}
.notification + .notification {
  margin-top: 1em;
}
.notification.success-notification {
  border-bottom-color: #00b300;
}
.notification .icon,
.notification .text {
  display: inline-block;
  vertical-align: middle;
}
.notification .text {
  width: calc(100% - 5em);
  text-align: left;
}
.notification .icon {
  font-size: 150%;
  padding-right: 1em;
  color: #ff2e2e;
}
.notification .icon.green {
  color: #00b300;
}
.notification .icon.close {
  position: absolute;
  padding-right: 0;
  right: 1em;
  font-size: 97.5%;
  color: #8a8a8a;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.notification .icon.close:hover {
  color: #ff2e2e;
  transform: scale(1.5);
  transition: 0.2s ease-out;
}

.scrollable-text {
  width: 100%;
  height: 300px !important;
  border-left: 5px solid #8a8a8a;
  overflow-y: auto;
  padding: 12px;
  margin: 24px 0;
  text-align: start;
  background-color: rgba(0, 0, 0, 0.03);
}

.download-link {
  color: white;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2%;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.sc-bdVaJa {
  background-color: transparent !important;
  color: grey !important;
  border-radius: 0px !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-color: black !important;
}

.gXAVlO {
  background-color: #34cc62 !important;
  border-color: white !important;
}

.evabGF {
  background-color: #34cc62 !important;
  border-color: white !important;
}

.jDGYoj svg {
  color: red !important;
}

.iCQPbd {
  color: red !important;
}

/*# sourceMappingURL=style.css.map */
