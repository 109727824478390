.hour-dropdown{
	float:left;
}

.Dropdown-control{
	height:45px;
	border-left:0px;
	border-top:0px;
	border-right:0px;
}

.Dropdown-control .Dropdown-arrow{
	padding:0px;
	margin-top:5px;
}

.Dropdown-arrow{
	font-size:10px;
}

.Dropdown-menu{
	position:absolute;
	z-index:999999;
}